<template>
    <div class="container">
         <div class="content">
          <div class="left">
            <img src="https://newgamemarket.oss-cn-beijing.aliyuncs.com/tbh_pc_images/guarantee.png" alt="中介担保" class="gaurantee-image">
          </div>
          <div class="right">
            <p class="title">官方客服</p>
            <ul>
                <li>
                    <div class="kefu-left">
                        <img class="kefu-avatar" src="../../../public/images/home/kefu001.png" alt="客服头像" >
                        <p class="kefu-nickname">晶核中介客服西西</p>
                    </div>
                    <div class="kefu-right">
                        <p class="kefu-btn-text">担保咨询</p>
                    </div>
                </li>
                <li>
                    <div class="kefu-left">
                        <img class="kefu-avatar" src="../../../public/images/home/kefu001.png" alt="客服头像" >
                        <p class="kefu-nickname">绝地CF飞车中介客服</p>
                    </div>
                    <div class="kefu-right">
                        <p class="kefu-btn-text">担保咨询</p>
                    </div>
                </li>
                <li>
                    <div class="kefu-left">
                        <img class="kefu-avatar" src="../../../public/images/home/kefu001.png" alt="客服头像" >
                        <p class="kefu-nickname">晶核中介客服西西</p>
                    </div>
                    <div class="kefu-right">
                        <p class="kefu-btn-text">担保咨询</p>
                    </div>
                </li>
                <li>
                    <div class="kefu-left">
                        <img class="kefu-avatar" src="../../../public/images/home/kefu001.png" alt="客服头像" >
                        <p class="kefu-nickname">绝地CF飞车中介客服</p>
                    </div>
                    <div class="kefu-right">
                        <p class="kefu-btn-text">担保咨询</p>
                    </div>
                </li>
                <li>
                    <div class="kefu-left">
                        <img class="kefu-avatar" src="../../../public/images/home/kefu001.png" alt="客服头像" >
                        <p class="kefu-nickname">晶核中介客服西西</p>
                    </div>
                    <div class="kefu-right">
                        <p class="kefu-btn-text">担保咨询</p>
                    </div>
                </li>
            </ul>
          </div>
         </div>
         <content-footer></content-footer>
    </div>
</template>
  
  <script>
  import ContentFooter from "../home/component/content-footer.vue"
  export default {
    name:"Ganrantee",
    components:{
        ContentFooter
    }
  }
  </script>
  
  <style lang="less" scoped>
  .container{
      background:#F5F5F5;
      width: calc(100vw);
      min-width: 1440px;
      .content{
          margin: 0 auto;
          width: 1200px;
          display: flex;
          justify-content: space-between;
          .left{
            margin-top: 20px;
            width: 860px;
            height: 1260px;
            .gaurantee-image{
                width: 860px;
                height: 1260px;
            }
          }
          .right{
            margin-top: 20px;
            background-color: #FFFFFF;
            margin-left: 20px;
            width: 320px;
            // height: 487px;
            height: calc(50vh);
            border-radius: 6px;
            .title{
                margin-top: 12px;
                margin-left: 14px;
                height: 20px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 15px;
                color: #222222;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
            ul{
                padding: 0 14px;
                li{
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    list-style: none;
                    cursor: default;
                    .kefu-left{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .kefu-avatar{
                            width: 50px;
                            height: 50px;
                        }
                        .kefu-nickname{
                            margin-left: 14px;
                            height: 19px;
                            font-family: MicrosoftYaHei;
                            font-size: 14px;
                            color: #1A1A1A;
                            line-height: 19px;
                            text-align: left;
                            font-style: normal;
                        }
                    }
                    .kefu-right{
                        width: 76px;
                        height: 30px;
                        background: #F02233;
                        border-radius: 6px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .kefu-btn-text{
                            height: 17px;
                            font-family: MicrosoftYaHei;
                            font-size: 13px;
                            color: #FFFFFF;
                            line-height: 17px;
                            text-align: right;
                            font-style: normal;
                        }
                    }

                }
            }
          }
      }
  }
  
  </style>